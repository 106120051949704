import {
  CheckboxParamter,
  ImpressaoActionContainer,
  ImpressaoCard,
  ImpressionWaitActionContainer,
  ImpressoesContainer,
  ImpressoesSection,
  InfoAction,
  ProgressBar,
  StatusInfo,
} from './styles'
import iconEyes from '../../assets/icon-eyes.svg'
import iconChange from '../../assets/icon-change.svg'
import iconLoading from '../../assets/icon-loading.svg'
import iconCheck from '../../assets/icon-check.svg'
import { useState, useEffect } from 'react'
import { parametrosRepository } from '../../../../api/repositories/parametrosRepository'

export function Impressoes({
  setModalImagemCapa,
  setModalImagemContraCapa,
  setLinkCapaExiste,
  setLinkContraCapaExiste,
  setLoad,
}) {
  const [loading, setLoading] = useState(false)

  const [uploadCapaStatus, setUploadCapaStatus] = useState('Substituir')
  const [capa, setCapa] = useState(null)
  const [uploadProgressCapa, setUploadProgressCapa] = useState(0)
  const [checkboxCapa, setCheckboxCapa] = useState(false)
  const [isUploadingCapa, setIsUploadingCapa] = useState(false)

  const [uploadContraCapaStatus, setUploadContraCapaStatus] =
    useState('Substituir')
  const [contraCapa, setContraCapa] = useState(null)
  const [uploadProgressContraCapa, setUploadProgressContraCapa] = useState(0)
  const [checkboxContraCapa, setCheckboxContraCapa] = useState(false)

  const [isUploadingContraCapa, setIsUploadingContraCapa] = useState(false)

  const URL_PADRAO_TEMPLATE = {
    CAPA: 'https://kaminocrm.com.br:7778/public/Capa.1.png',
    CONTRA_CAPA: 'https://kaminocrm.com.br:7778/public/Contra-capa.1.png',
  }

  useEffect(() => {
    buscarImagensSistema()
  }, [loading])

  const addCapaProgress = (percentCompleted) => {
    setUploadProgressCapa(percentCompleted)
  }

  async function enviarCapaCheck(checkboxCapa) {
    try {
      await parametrosRepository.configurarCapaAssociacao(
        capa,
        checkboxCapa,
        addCapaProgress,
      )
    } catch (error) {
      console.log(error)
    }
  }

  async function enviarCapa(base64File, checkboxCapa) {
    try {
      setIsUploadingCapa(true)
      setUploadCapaStatus('Aguarde...')
      await parametrosRepository.configurarCapaAssociacao(
        base64File,
        checkboxCapa,
        addCapaProgress,
      )
      setUploadCapaStatus('Concluído!')
      setTimeout(() => {
        setLoading(true)
        setUploadCapaStatus('Substituir')
        setCapa(null)
        setUploadProgressCapa(0)
        setIsUploadingCapa(false)
      }, 3000)
    } catch (error) {
      console.log('Erro ao fazer upload:', error)
      setUploadProgressCapa(0)
      setIsUploadingCapa(false)
      setUploadCapaStatus('Substituir')
    }
  }

  const handleCapaChange = (e) => {
    const file = e.target.files[0]
    setCapa(file)

    const reader = new FileReader()

    reader.onloadend = async () => {
      const base64String = reader.result.split(',')[1]
      try {
        await enviarCapa(base64String, checkboxCapa)
      } catch (error) {
        console.log('Erro ao enviar arquivo:', error)
      }
    }

    reader.readAsDataURL(file)
  }

  // CONTRA CAPA

  const addContraCapaProgress = (percentCompleted) => {
    setUploadProgressContraCapa(percentCompleted)
  }

  async function enviarContraCapaChecked(checkboxContraCapa) {
    try {
      await parametrosRepository.configurarContraCapaAssociacao(
        contraCapa,
        checkboxContraCapa,
        addContraCapaProgress,
      )
    } catch (error) {
      console.log('Erro ao fazer upload:', error)
    }
  }

  async function enviarContraCapa(base64File, checkboxContraCapa) {
    try {
      setIsUploadingContraCapa(true)
      setUploadContraCapaStatus('Aguarde...')
      await parametrosRepository.configurarContraCapaAssociacao(
        base64File,
        checkboxContraCapa,
        addContraCapaProgress,
      )
      setUploadContraCapaStatus('Concluído!')
      setTimeout(() => {
        setLoading(true)
        setUploadContraCapaStatus('Substituir')
        setContraCapa(null)
        setUploadProgressContraCapa(0)
        setIsUploadingContraCapa(false)
      }, 3000)
    } catch (error) {
      console.log('Erro ao fazer upload:', error)
      setUploadProgressContraCapa(0)
      setIsUploadingContraCapa(false)
      setUploadContraCapaStatus('Substituir')
    }
  }

  const handleCheckboxContraCapaChange = (e) => {
    const novoCheckboxContraCapa = e.target.checked
    setCheckboxContraCapa(novoCheckboxContraCapa)

    enviarContraCapaChecked(novoCheckboxContraCapa)
  }

  const handleContraCapaChange = (e) => {
    const file = e.target.files[0]
    setContraCapa(file)

    const reader = new FileReader()

    reader.onloadend = async () => {
      const base64String = reader.result.split(',')[1] // Pega a parte Base64 da string
      try {
        await enviarContraCapa(base64String, checkboxContraCapa)
      } catch (error) {
        console.log('Erro ao enviar arquivo:', error)
      }
    }

    reader.readAsDataURL(file)
  }

  //GERAL

  async function buscarImagensSistema() {
    try {
      setLoad(true)
      const data = await parametrosRepository.buscarImagensSistema()

      const CAPA_ASSOCIACAO = data.filter((value) => {
        return value.IMG_DESCRICAO === 'CAPA_ASSOCIACAO'
      })
      const CONTRA_CAPA_ASSOCIACAO = data.filter((value) => {
        return value.IMG_DESCRICAO === 'CONTRA_CAPA_ASSOCIACAO'
      })

      if (CAPA_ASSOCIACAO[0].ATIVO) {
        console.log('TESTANDO O CAPA', CAPA_ASSOCIACAO[0].ATIVO)
        setCheckboxCapa(CAPA_ASSOCIACAO[0].ATIVO)
      }

      if (CAPA_ASSOCIACAO[0].IMG_URL) {
        setLinkCapaExiste(CAPA_ASSOCIACAO[0].IMG_URL)
        setCapa(CAPA_ASSOCIACAO[0].IMG_URL)
      } else {
        setLinkCapaExiste(URL_PADRAO_TEMPLATE.CAPA)
      }

      if (CONTRA_CAPA_ASSOCIACAO[0].ATIVO) {
        console.log('AQIUIII', CONTRA_CAPA_ASSOCIACAO[0].ATIVO)
        setCheckboxContraCapa(CONTRA_CAPA_ASSOCIACAO[0].ATIVO)
      }

      if (CONTRA_CAPA_ASSOCIACAO[0].IMG_URL) {
        setLinkContraCapaExiste(CONTRA_CAPA_ASSOCIACAO[0].IMG_URL)
        setContraCapa(CONTRA_CAPA_ASSOCIACAO[0].IMG_URL)
      } else {
        setLinkContraCapaExiste(URL_PADRAO_TEMPLATE.CONTRA_CAPA)
      }

      setLoad(false)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ImpressoesContainer>
      <ImpressoesSection>
        <ImpressaoCard>
          <CheckboxParamter>
            <input
              type="checkbox"
              checked={checkboxCapa}
              onChange={(e) => {
                const novoCheckboxCapa = e.target.checked

                setCheckboxCapa(novoCheckboxCapa)

                enviarCapaCheck(novoCheckboxCapa)
              }}
            />
          </CheckboxParamter>
          <div>
            <h3>Imprimir Capa</h3>
            <p>
              Marque esta opção se deseja imprimir a Capa para a Oportunidade e
              envie seu arquivo personalizado em formato PNG e em tamanho A4!
            </p>
          </div>
        </ImpressaoCard>
        {isUploadingCapa ? (
          <ImpressionWaitActionContainer>
            <InfoAction>
              {uploadCapaStatus === 'Aguarde...' ? (
                <img src={iconLoading} alt="" className="rotate" />
              ) : (
                <img src={iconCheck} alt="" className="success" />
              )}
              <StatusInfo status={uploadCapaStatus}>
                {uploadCapaStatus}
              </StatusInfo>
            </InfoAction>
            {uploadCapaStatus === 'Aguarde...' && (
              <ProgressBar progress={uploadProgressCapa} />
            )}
          </ImpressionWaitActionContainer>
        ) : (
          <ImpressaoActionContainer checkbox={checkboxCapa}>
            <div onClick={() => setModalImagemCapa(true)}>
              <img src={iconEyes} alt="" />
              <span>Visualizar</span>
            </div>
            <label className="alter" htmlFor="fileCapa">
              <InfoAction checkbox={checkboxCapa}>
                <input
                  type="file"
                  id="fileCapa"
                  onChange={handleCapaChange}
                  style={{ display: 'none' }}
                  disabled={!checkboxCapa}
                />

                <div>
                  <img src={iconChange} alt="" />
                  <StatusInfo status={uploadCapaStatus}>
                    {uploadCapaStatus}
                  </StatusInfo>
                </div>
              </InfoAction>
            </label>
          </ImpressaoActionContainer>
        )}
      </ImpressoesSection>
      <ImpressoesSection>
        <ImpressaoCard>
          <CheckboxParamter>
            <input
              type="checkbox"
              checked={checkboxContraCapa}
              onChange={handleCheckboxContraCapaChange}
            />
          </CheckboxParamter>
          <div>
            <h3>Imprimir Contra-Capa</h3>
            <p>
              Marque esta opção se deseja imprimir a Contra-Capa para a
              Oportunidade e envie seu arquivo personalizado em formato PNG e em
              tamanho A4!
            </p>
          </div>
        </ImpressaoCard>
        {isUploadingContraCapa ? (
          <ImpressionWaitActionContainer>
            <InfoAction>
              {uploadContraCapaStatus === 'Aguarde...' ? (
                <img src={iconLoading} alt="" className="rotate" />
              ) : (
                <img src={iconCheck} alt="" className="success" />
              )}
              <StatusInfo status={uploadContraCapaStatus}>
                {uploadContraCapaStatus}
              </StatusInfo>
            </InfoAction>
            {uploadContraCapaStatus === 'Aguarde...' && (
              <ProgressBar progress={uploadProgressContraCapa} />
            )}
          </ImpressionWaitActionContainer>
        ) : (
          <ImpressaoActionContainer checkbox={checkboxContraCapa}>
            <div onClick={() => setModalImagemContraCapa(true)}>
              <img src={iconEyes} alt="" />
              <span>Visualizar</span>
            </div>
            <label className="alter" htmlFor="fileContraCapa">
              <InfoAction checkbox={checkboxContraCapa}>
                <input
                  type="file"
                  id="fileContraCapa"
                  onChange={handleContraCapaChange}
                  style={{ display: 'none' }}
                  disabled={!checkboxContraCapa}
                />

                <div>
                  <img src={iconChange} alt="" />
                  <StatusInfo status={uploadContraCapaStatus}>
                    {uploadContraCapaStatus}
                  </StatusInfo>
                </div>
              </InfoAction>
            </label>
          </ImpressaoActionContainer>
        )}
      </ImpressoesSection>
    </ImpressoesContainer>
  )
}
