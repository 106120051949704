import React, { useState, useContext, useEffect } from 'react'
import Select from 'react-select'
import { IoMdClose } from 'react-icons/io'
import { BackgroundModal, X } from '../Modal/style/styledModal'
import { AuthContext } from '../../../../../context/auth'
import './style.css'
import { parametrosRepository } from '../../../../../api/repositories/parametrosRepository'
import { Listbox } from '@headlessui/react'
import { Checkbox } from '@mui/material'
import { ListboxWrapper } from './styles'

function FilterModal({
  setFilterModal,
  funis,
  filterStatus,
  setFilterStatus,
  setFunilNome,
  selectedVendedores,
  setSelectedVendedores,
}) {
  // const { PER_ID } = useContext(AuthContext)
  const perId = sessionStorage.getItem('PER_ID')
  const { FUN_ID, setFUN_ID } = useContext(AuthContext)
  const [funilSelecionado, setFunilSelecionado] = useState({})
  const [vendorsSelected, setVendorsSelected] = useState(selectedVendedores)
  const [statusSelecionado, setStatusSelecionado] = useState({})

  const [vendedores, setVendedores] = useState([])
  const [query, setQuery] = useState('')

  async function buscarVendedores() {
    try {
      const data = await parametrosRepository.buscarVendedores()

      setVendedores(data)
      console.log(data)
    } catch (error) {
      console.log(error)
    }
  }

  console.log(vendedores)
  console.log(vendorsSelected)
  console.log(selectedVendedores)

  const status = [
    {
      value: 'TODAS',
      label: 'TODAS',
    },
    {
      value: 'PENDENTE',
      label: 'PENDENTES',
    },
    {
      value: 'PERDIDA',
      label: 'PERDIDAS',
    },
    {
      value: 'CONVERTENDO',
      label: 'CONVERTENDO',
    },
    {
      value: 'CONVERTIDA',
      label: 'CONVERTIDAS',
    },
  ]

  useEffect(() => {
    const fun = funis.find(function (funil) {
      return funil.value === FUN_ID
    })

    const st = status.find(function (statuses) {
      return statuses.value === filterStatus
    })

    if (FUN_ID) {
      setFunilSelecionado(fun)
    }
    if (filterStatus) {
      setStatusSelecionado(st)
    }

    buscarVendedores()
  }, [])

  const filtrarVendedores =
    query === ''
      ? vendedores
      : vendedores.filter((vendedor) => {
          return vendedor.label.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <BackgroundModal>
      <div className="Modal__Container">
        <div className="Modal__Header">
          <h2>Filtro Personalizado</h2>
          <X onClick={() => setFilterModal(false)}>
            <IoMdClose size={17} />
          </X>
        </div>

        <div className="Modal__Body">
          <div className="Filter__Item">
            <span className="Title">Por Funil</span>
            <Select
              value={funilSelecionado}
              options={funis}
              placeholder="Selecione..."
              onChange={setFunilSelecionado}
            />
          </div>
          {perId === '1' && (
            <div className="Filter_Vendor">
              <span className="Title">Por Vendedor</span>
              <ListboxWrapper>
                <Listbox
                  multiple
                  value={vendorsSelected}
                  onChange={setVendorsSelected}
                >
                  <>
                    <Listbox.Button className="ListboxButton">
                      {vendorsSelected.length === 0 ||
                      vendorsSelected.length === selectedVendedores.length
                        ? 'Selecione os Vendedores'
                        : vendorsSelected
                            .map((vendedor) => vendedor.label)
                            .join(', ')}
                    </Listbox.Button>

                    <Listbox.Options className="combobox-options">
                      <div className="combobox-search">
                        <input
                          type="text"
                          placeholder="Pesquisar..."
                          value={query}
                          onChange={(event) => setQuery(event.target.value)}
                          onKeyDown={(event) => {
                            if (event.key === ' ') {
                              event.stopPropagation()
                            }
                          }}
                        />
                      </div>

                      <div
                        className="combobox-option"
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={(event) => {
                          event.stopPropagation()
                          vendorsSelected.length === vendedores.length
                            ? setVendorsSelected([]) // Desmarca todos
                            : setVendorsSelected(vendedores) // Marca todos
                        }}
                      >
                        <Checkbox
                          checked={vendorsSelected.length === vendedores.length}
                          onClick={(event) => {
                            event.stopPropagation()
                            event.target.checked
                              ? setVendorsSelected(vendedores) // Marca todos
                              : setVendorsSelected([]) // Desmarca todos
                          }}
                        />
                        <span style={{ fontSize: '12px' }}>
                          Selecionar todos
                        </span>
                      </div>

                      {filtrarVendedores.map((vendedor) => (
                        <Listbox.Option
                          key={vendedor.value}
                          value={vendedor}
                          className="combobox-option"
                        >
                          {({ selected }) => (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              onClick={(event) => {
                                event.stopPropagation()
                                setVendorsSelected((prevSelected) => {
                                  const alreadySelected = prevSelected.some(
                                    (v) => v.value === vendedor.value,
                                  )
                                  return alreadySelected
                                    ? prevSelected.filter(
                                        (v) => v.value !== vendedor.value,
                                      )
                                    : [...prevSelected, vendedor]
                                })
                              }}
                            >
                              <Checkbox
                                checked={vendorsSelected.some(
                                  (v) => v.value === vendedor.value,
                                )}
                                onClick={(event) => {
                                  event.stopPropagation()
                                  setVendorsSelected((prevSelected) => {
                                    const alreadySelected = prevSelected.some(
                                      (v) => v.value === vendedor.value,
                                    )
                                    return alreadySelected
                                      ? prevSelected.filter(
                                          (v) => v.value !== vendedor.value,
                                        )
                                      : [...prevSelected, vendedor]
                                  })
                                }}
                              />
                              <span
                                style={{ fontSize: '12px' }}
                                onClick={(event) => {
                                  event.stopPropagation()
                                  setVendorsSelected((prevSelected) => {
                                    const alreadySelected = prevSelected.some(
                                      (v) => v.value === vendedor.value,
                                    )
                                    return alreadySelected
                                      ? prevSelected.filter(
                                          (v) => v.value !== vendedor.value,
                                        )
                                      : [...prevSelected, vendedor]
                                  })
                                }}
                              >
                                {vendedor.label}
                              </span>
                            </div>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </>
                </Listbox>
              </ListboxWrapper>
            </div>
          )}

          <div className="Filter__Item">
            <div className="Title">Por Status</div>
            <Select
              value={statusSelecionado}
              options={status}
              placeholder="Selecione..."
              onChange={setStatusSelecionado}
            />
          </div>
        </div>

        <div className="Modal__Footer">
          <button
            className="BtnSubmit__Filter"
            disabled={!funilSelecionado || !statusSelecionado}
            onClick={() => {
              setFUN_ID(funilSelecionado.value)
              setFilterStatus(statusSelecionado.value)
              setFunilNome(funilSelecionado.label)
              setSelectedVendedores(vendorsSelected)
              setFilterModal(false)
            }}
          >
            Filtrar
          </button>
        </div>
      </div>
    </BackgroundModal>
  )
}

export default FilterModal
