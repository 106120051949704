import styled from 'styled-components'

export const InicioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 48px 64px 0;
  box-sizing: border-box;

  h2 {
    font-size: 2.5em;
  }

  .filterBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 43px;
    border-radius: 8px;
    background-color: #eeeeef;
    cursor: pointer;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`

export const DataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0px 0px 20px;
  box-sizing: border-box;
`

export const CardData = styled.div`
  height: 8.75rem;
  min-width: 400px;

  display: flex;
  flex-direction: row;
  gap: 20px;
  z-index: 2;
  padding: 20px;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0 2px 10px #d9d9d980;
  background-color: #fff;
  box-sizing: border-box;

  h1 {
    font-size: 2.5rem;
    font-weight: bold;
  }

  h3 {
    margin: 0;
    font-size: 1.375rem;
    font-weight: bold;
  }
  span {
    font-size: 0.75rem;
    font-weight: 300;
    margin: 0;

    color: #353535;
  }

  > img {
    height: 100px;
    width: 78px;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.625rem;
    box-sizing: border-box;
  }
`
