import React, { useEffect, useRef, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import DraggableItem from './DraggableItem'
import {
  HiMiniBarsArrowUp,
  HiMiniBarsArrowDown,
  HiChevronUp,
} from 'react-icons/hi2'
import Loading from '../../../../Loading/Loading'
import arrowDown from '../../../assets/group-order.svg'
import arrowUp from '../../../assets/group-order-reverse.svg'
import { CircularProgress } from '@mui/material'

const DroppableWrapper = ({
  data,
  keyDrop,
  selectedVendedores,
  setColunas,
  OptionsButton,
  setOpenModal,
  setDataResumo,
  mask,
}) => {
  const itemsPerPage = 10
  const [btn, setBtn] = useState(false)
  const [itemsCount, setItemsCount] = useState(itemsPerPage)
  const cardContainerRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [filteredItem, setFilteredItem] = useState([])

  useEffect(() => {
    const filtrados = data.itens.filter((item) =>
      selectedVendedores.includes(item.VEN_ID),
    )

    setFilteredItem(filtrados)
  }, [data.itens, selectedVendedores])

  useEffect(() => {
    const cardContainer = cardContainerRef.current
    if (cardContainer) {
      cardContainer.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (cardContainer) {
        cardContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [itemsCount, data.itens.length])

  const loadMoreItems = () => {
    setLoading(true) // Inicia o loading
    setTimeout(() => {
      setItemsCount((prev) => prev + itemsPerPage)
      setLoading(false) // Termina o loading
    }, 500) // Atraso de 500ms
  }

  const handleScroll = () => {
    if (cardContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = cardContainerRef.current

      if (
        scrollTop + clientHeight >= scrollHeight - 5 &&
        itemsCount < data.itens.length
      ) {
        loadMoreItems()
      }
    }
  }

  const valuesVendors = data.itens.filter((item) =>
    selectedVendedores.includes(item.VEN_ID),
  )

  const valuePlanos = valuesVendors.map((value) => value.VALOR_PLANO)

  const totalValuePlanos = valuePlanos.reduce((acc, curr) => acc + curr, 0)

  const formattedTotal = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(totalValuePlanos)

  console.log(totalValuePlanos)

  return (
    <div className="column" key={keyDrop}>
      <div className="top">
        <span>
          <div
            className="cor_categoria"
            style={{ backgroundColor: data.cor }}
          />
          <div className="nome_categoria">{data.titulo}</div>
        </span>

        <button
          className="btn__order"
          onClick={() => {
            setBtn(!btn)
            setColunas((prev) => ({
              ...prev,
              [data.titulo.toUpperCase()]: {
                titulo: data.titulo,
                cor: data.cor,
                valorplanos: data.valorplanos,
                itens: data.itens.reverse(),
              },
            }))
          }}
        >
          {btn ? <img src={arrowUp} alt="" /> : <img src={arrowDown} alt="" />}
        </button>
      </div>

      <div className="header_values">
        <span>
          {data.itens.length > 0
            ? `${valuesVendors.length} ${
                valuesVendors.length !== 1 ? 'Negociações' : 'Negociação'
              }`
            : '0 Negociações'}
        </span>
        <span>{formattedTotal ? formattedTotal : 'R$: 0,00'}</span>
      </div>

      <Droppable droppableId={`${keyDrop}`}>
        {(provided, snapshot) => {
          const combinedRef = (node) => {
            cardContainerRef.current = node
            provided.innerRef(node)
          }

          return (
            <>
              <div
                className="card_container"
                ref={combinedRef}
                {...provided.droppableProps}
              >
                {filteredItem.slice(0, itemsCount).map((el, index) => {
                  return (
                    <DraggableItem
                      el={el}
                      index={index}
                      OptionsButton={OptionsButton}
                      setOpenModal={setOpenModal}
                      setDataResumo={setDataResumo}
                      mask={mask}
                    />
                  )
                })}
                {provided.placeholder}
                {loading && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '0.5rem',
                    }}
                  >
                    <CircularProgress color="inherit" size="20px" />
                  </div>
                )}
              </div>
              <div style={{ padding: '0.5rem' }}>
                {filteredItem.length > 3 && (
                  <HiChevronUp
                    size={21}
                    className="buttonDownItens"
                    onClick={() => {
                      if (cardContainerRef.current) {
                        cardContainerRef.current.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        })
                      }
                    }}
                  />
                )}
              </div>
            </>
          )
        }}
      </Droppable>
    </div>
  )
}

export default DroppableWrapper
