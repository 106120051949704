import React, { useState, useContext, useEffect } from 'react'
import Select from 'react-select'
import { IoMdClose } from 'react-icons/io'
import './style/style.css'
import { BackgroundModal, X } from '../Oportunidades/Modal/style/styledModal'
import { SelectFiltroOpo } from '../Relatorios/styledRelatorios'

export function ModalFilter({
  setFilterModal,
  vendedores,
  FILHOS,
  setFILHOS,
  setVEN_ID,
  selectedVen,
  setSelectedVen,
}) {
  const [isChecked, setIsChecked] = useState(FILHOS)

  return (
    <BackgroundModal>
      <div className="Modal__Container">
        <div className="Modal__Header">
          <h3 style={{ fontSize: '1.1rem' }}>Filtro Personalizado</h3>
          <X onClick={() => setFilterModal(false)}>
            <IoMdClose size={17} />
          </X>
        </div>

        <div className="Modal__Body">
          <div className="Filter__Item">
            <div className="Filter_Header">
              <span className="Title">Por Funil</span>
              <div className="checkbox_children">
                <div>
                  <input
                    type="checkbox"
                    value={isChecked}
                    onChange={() => {
                      setIsChecked(!isChecked)
                    }}
                    checked={isChecked ? true : false}
                  />
                  <label htmlFor="">Mostrar filhos</label>
                </div>
              </div>
            </div>
            <SelectFiltroOpo
              name=""
              id=""
              style={{ margin: '10px 0', minHeight: '38px' }}
              value={selectedVen}
              onChange={(e) => setSelectedVen(e.target.value)}
            >
              <option value="null">Ver todas as oportunidades...</option>
              {vendedores.map((ven) => {
                return (
                  <option key={ven.value} value={ven.value}>
                    {ven.label}
                  </option>
                )
              })}
            </SelectFiltroOpo>
          </div>
        </div>

        <div className="Modal__Footer">
          <button
            className="BtnSubmit__Filter"
            onClick={() => {
              setVEN_ID(selectedVen)
              setFILHOS(isChecked)
              setTimeout(() => {
                setFilterModal(false)
              }, 500)
            }}
          >
            Filtrar
          </button>
        </div>
      </div>
    </BackgroundModal>
  )
}
