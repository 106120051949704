import styled from 'styled-components'
import { COLORS } from '../../../../../../colors.js'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  margin: 0 6rem;

  @media only screen and (max-width: 1400px) {
    margin: 0 4rem;
    /* width: 100%;
    overflow-x: auto; */
  }
  @media only screen and (max-width: 1399px) {
    margin: 0;
    /* width: 100%;
    overflow-x: auto; */
  }
`

export const KanbanWrapper = styled.div`
  height: 60vh;
  max-height: 71vh;

  @media only screen and (max-width: 450px) {
    height: 59vh;
    max-height: 59vh;
  }

  @media only screen and (min-height: 721px) {
    height: 71vh;
    max-height: 76vh;
  }

  .container {
    width: 100%;
    max-height: 100%;
    height: 100%;
    overflow: auto;
    flex: 1 1 0%;
    position: relative;
    box-sizing: border-box;

    ::-webkit-scrollbar {
      width: 1px;
    }
    ::-webkit-scrollbar-track {
      background: #fff;
    }

    ::-webkit-scrollbar-thumb {
      background: #ddd;
      border: 4px solid #fff;
    }
  }

  .columns {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    // align-items: stretch;
    user-select: none;
    /* padding: 0px 0px 40px 20px; */
    width: fit-content;
  }

  .wrapper {
    display: flex;
    gap: 0.45rem;
    width: 300px;
    box-sizing: border-box;
    // flex: 1 1 auto;

    @media only screen and (max-width: 450px) {
      padding-bottom: 0;
    }
  }

  .column {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-self: auto;
    flex: 1 1 auto;
    width: auto;
    border-radius: 15px;
    max-height: 41.5rem;
    box-sizing: border-box;
    max-width: 282px;
    min-width: 282px;

    @media only screen and (max-width: 450px) {
      height: 22rem;
      min-height: 22rem;
    }

    @media only screen and (min-height: 600px) {
      max-height: 23.5rem;
    }

    @media only screen and (min-height: 750px) {
      max-height: 30.5rem;
    }

    @media only screen and (min-height: 900px) {
      max-height: 38.5rem;
    }

    /* @media only screen and (max-height: 1200px) {
      max-height: 56vh;
    } */

    .btn__order {
      border: none;
      /* background-color: red; */
      padding: 0;
      border-radius: 5px;
      transition: 0.2s;
      /* :hover {
        filter: brightness(90%);
      } */
    }

    .top {
      background-color: #f2f2f2;
      padding: 15px 20px 0;
      border-radius: 15px 15px 0 0;
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        .cor_categoria {
          height: 16px;
          width: 16px;
          border-radius: 9999px;
          margin-right: 5px;
        }
        .nome_categoria {
          font-weight: 600;
          color: #31303f;
          text-transform: uppercase;
          overflow-x: hidden;
          max-width: 200px;

          white-space: nowrap;
        }
      }
    }

    .header_values {
      /* border-bottom: 1px solid #e0e0e0; */
      display: flex;
      justify-content: space-between;
      padding: 8px 15px;
      margin: 15px 10px;
      background-color: #e3e3e3;
      border-radius: 20px;
      height: 30px;
      font-weight: 600;
      font-size: 11px;
      color: #1e1e1e;
      box-sizing: border-box;
    }

    .buttonDownItens {
      display: flex;
      width: 100%;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      color: #534545;

      :hover {
        color: #000000;
      }
    }
  }

  .card_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    padding: 0 10px 25px;
    /* background-color: red; */
    // -> min-height: 99%;
    /* height: 35rem; */
    overflow: hidden;
    overflow-y: scroll;
    gap: 10px;

    ::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: 450px) {
      padding: 0 15px;
      min-height: 18rem;
      height: 18rem;
      max-height: 18rem;
      overflow-y: auto;
    }

    .btn-wrapper {
      position: relative;
      height: auto;
      width: auto;
      z-index: 1;
      margin-top: -3px;

      .options-btn {
        background-color: #fff;
        display: flex;
        border: none;
        padding: 0;
        margin: 0;
        text-align: center;
        align-items: center;
        color: #9b9aad;
        :hover {
          color: #83869e;
          cursor: pointer;
        }
      }
    }

    #convertida {
      background-color: #c7ffe7;
      color: #278a60;
    }
    #perdida {
      background-color: #ffe0e6;
      color: #f12148;
    }
    #convertendo {
      background-color: #f0d5ff;
      color: #a421f1;
    }
    #pendente {
      background-color: #c8ddff;
      color: #0049c4;
      font-size: 9px;
    }

    .card {
      width: 262px;
      min-height: 172px;
      max-height: 172px;
      background-color: #fff;
      border-radius: 10px;
      padding: 15px 20px;
      /* border: 1px solid #dbe1e9bf; */
      box-shadow: 0 2px 5px 0 rgba(217, 217, 217, 0.5);
      position: relative;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      :hover {
        cursor: pointer;
      }

      .item {
        display: block;
        margin-bottom: 2px;
        color: #000;
        font-weight: 300;
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        inline-size: 100%;
        overflow: hidden;
      }

      #nome,
      #tarefa {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #31303f;
        font-weight: 600;
        font-size: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;

        .nome_span {
          margin: 0;
          inline-size: 100%;
          overflow: hidden;
          font-weight: 600;
          font-size: 18px;
          line-height: normal;
        }
      }

      #modelo {
        font-size: 13px;
        color: #000;
      }

      .bottom {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        font-size: 12px;
        /* font-weight: 700; */
        /* margin-top: 10px; */
        gap: 2px;

        .under-bottom {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          gap: 0.45rem;

          .icons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.3rem;
            box-sizing: border-box;
          }

          .icons-info {
            display: flex;
            gap: 6px;
          }

          .status {
            display: flex;
            justify-content: center;
            width: 80px;
            padding: 3px 6px;
            border-radius: 15px;
            color: white;
            font-weight: 700;
            white-space: nowrap;
            font-size: 9px;

            > span {
            }
          }
        }

        #data-criacao {
          color: #565c70;
          font-size: 11px;
          font-weight: 500;
        }

        #agendado {
          margin-left: 20px;
          color: #fff;

          .icone_agendado {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            width: 21px;
            background-color: #4c8ced;
            border-radius: 50px;
          }
        }
        #origem {
          display: flex;
          align-items: center;
          color: #515c83;
        }

        #vendedor,
        #tarefa {
          display: flex;
          align-items: center;
          font-size: 12px;
        }
      }
    }
  }
`
