import {
  CardData,
  DataContainer,
  HeaderContainer,
  InicioContainer,
} from './novoInicio'
import iconFilter from '../../assets/icon-filter.svg'
import oportunidadeConvertida from '../../assets/Oportunidades_Convertidas.svg'
import oportunidadePendente from '../../assets/Oportunidades_Pendentes.svg'
import oportunidadePerdidas from '../../assets/Oportunidades_Perdidas.svg'
import oportunidadeTodas from '../../assets/Oportunidades_Todas.svg'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../context/auth'
import { oportunidadeRepository } from '../../../../api/repositories/oportunidadesRepository'
import { filtroRepository } from '../../../../api/repositories/filtroRepository'
import { ModalFilter } from './ModalFilter'

export function NovoInicio() {
  const LOGIN = sessionStorage.getItem('LOGIN')
  const ASS_COD = sessionStorage.getItem('ASS_COD')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const perId = sessionStorage.getItem('PER_ID')

  const { PER_ID, FUN_ID, setFUN_ID } = useContext(AuthContext)

  const [dados, setDados] = useState({})
  const [loading, setLoading] = useState(false)
  const [VEN_ID, setVEN_ID] = useState('')
  const [filterModal, setFilterModal] = useState(false)

  const funilAtual = sessionStorage.getItem('FUN_ID')

  const [vendedores, setVendedores] = useState([])
  const [selectedVen, setSelectedVen] = useState(null)

  const [FILHOS, setFILHOS] = useState(false)

  useEffect(() => {
    if (PER_ID == 0) {
      setVEN_ID(sessionStorage.getItem('VEN_ID'))
    }
  }, [PER_ID])

  useEffect(() => {
    if ((PER_ID == 0 && VEN_ID != '') || PER_ID == 1) {
      listarDashboardOportunidades()
      buscarFiltroVendedores()
    }
    listarFunisOportunidades()
    setLoading(false)
  }, [PER_ID, VEN_ID, FILHOS])

  console.log(dados)
  console.log(vendedores)

  async function listarDashboardOportunidades() {
    try {
      const data = await oportunidadeRepository.listarDashboardOportunidades(
        PER_ID,
        VEN_ID,
        FILHOS,
      )
      setDados(data)
      setLoading(false)
    } catch (error) {
      console.error('Erro no componente:', error)
      alert('Algo deu errado')
      setLoading(false)
    }
  }

  async function buscarFiltroVendedores() {
    try {
      const data = await filtroRepository.buscarFiltro(
        JWT_TOKEN,
        LOGIN,
        ASS_COD,
      )
      // console.log('OBTENDO FILTRO', data.dadosVendedor)
      setVendedores(data.dadosVendedor)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  async function listarFunisOportunidades() {
    try {
      const data = await oportunidadeRepository.listarFunisOportunidade(VEN_ID)
      // console.log('DADOS DOS FUNIS OPORTUNIDADE', data)
      console.log(funilAtual)
      if (!funilAtual) {
        setFUN_ID(data.funis[0].FUN_ID)
        sessionStorage.setItem('FUN_ID', FUN_ID)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <InicioContainer>
      {filterModal && (
        <ModalFilter
          setFilterModal={setFilterModal}
          vendedores={vendedores}
          FILHOS={FILHOS}
          setFILHOS={setFILHOS}
          setVEN_ID={setVEN_ID}
          selectedVen={selectedVen}
          setSelectedVen={setSelectedVen}
        />
      )}
      <header>
        <HeaderContainer>
          <div>
            <h2>Dashboard</h2>
          </div>
          {perId === '1' && (
            <div
              className="filterBox"
              onClick={() => {
                setFilterModal(true)
              }}
            >
              <img src={iconFilter} alt="" />
            </div>
          )}
        </HeaderContainer>
      </header>
      <DataContainer>
        <CardData>
          <img src={oportunidadePendente} alt="" />
          <div>
            <div>
              <h3>Em Andamento</h3>
              <span>Todo Período</span>
            </div>
            <h1>{dados.totalDeOportunidadesPendentes}</h1>
          </div>
        </CardData>
        <CardData>
          <img src={oportunidadeConvertida} alt="" />
          <div>
            <div>
              <h3>Convertidas</h3>
              <span>Todo Período</span>
            </div>
            <h1>{dados.totalDeOportunidadesConvertida}</h1>
          </div>
        </CardData>
        <CardData>
          <img src={oportunidadePerdidas} alt="" />
          <div>
            <div>
              <h3>Perdidas</h3>
              <span>Oportunidades que foram perdidas</span>
            </div>
            <h1>{dados.totalDeOportunidadesPerdidas}</h1>
          </div>
        </CardData>
        <CardData>
          <img src={oportunidadeTodas} alt="" />
          <div>
            <div>
              <h3>Todas</h3>
              <span>Todas as Oportunidades</span>
            </div>
            <h1>{dados.totalDeOportunidades}</h1>
          </div>
        </CardData>
      </DataContainer>
    </InicioContainer>
  )
}
